import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { withTranslation, WithTranslation } from 'react-i18next';
import queryString from 'query-string';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'modules/localization';

import { Routing } from 'components/routing';
import { setCookie } from 'utils/cookie';
import { recaptchaSiteKey } from 'modules/api-requests/const';
import { Header } from 'components/common/Header';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

import 'antd/lib/style/index.less';
import 'components/kit/vendors/antd/themes/default.less';
import 'components/kit/vendors/antd/themes/dark.less';
import 'modules/global-styles/global.scss';

const baseHost = window.location.hostname.split('.').slice(-2).join('.');
const query = queryString.parse(window.location.search);
const refId = query?.refid;
if (refId) {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  setCookie('referral', String(refId), {
    expires: date,
    domain: baseHost,
  });
}

const Index: React.FC<WithTranslation> = () => {
  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        <>
          <Header />
          <Routing />
        </>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
};

const WithTranslate = withTranslation()(Index);
const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(<WithTranslate />);
