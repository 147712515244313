import { Trans, useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { Alert } from 'antd';
import { setInterval, clearInterval } from 'worker-timers';

import { Anchor } from 'components/common/link/Anchor';

import { CONTACT_US_URL } from './constants';

import style from './style.module.scss';

export type LoginAlertError = {
  rateLimitRetryAfterSeconds?: number;
  errorCode: number;
  errorText: string;
};

type Props = LoginAlertError & {
  setErrorLoginAlert: (error: LoginAlertError) => void;
};

export const errorsInitState: LoginAlertError = {
  errorText: '',
  errorCode: 0,
};

const errorMessages = new Map([
  [
    4221,
    <Trans i18nKey="login:errorMessage.4221" key="deleted">
      <>Your account is deleted, please contact </>
      <Anchor target="_blank" href={CONTACT_US_URL}>
        support
      </Anchor>
    </Trans>,
  ],
  [
    4222,
    <Trans i18nKey="login:errorMessage.4222" key="blocked">
      <>Your account is blocked, please contact </>
      <Anchor target="_blank" href={CONTACT_US_URL}>
        support
      </Anchor>
    </Trans>,
  ],
]);

const TooManyRequest = ({
  rateLimitRetryAfterSeconds,
  setErrorLoginAlert,
}: Pick<Props, 'setErrorLoginAlert'> & { rateLimitRetryAfterSeconds: number }) => {
  const { t } = useTranslation('login');
  const [seconds, setSeconds] = useState(rateLimitRetryAfterSeconds);
  const getTime = () => {
    const minutes = Math.floor(seconds / 60);
    let secondsStr = String(seconds % 60);
    secondsStr = secondsStr.length === 1 ? '0' + secondsStr : secondsStr;

    return `${minutes}:${secondsStr}`;
  };
  useEffect(() => {
    const timerId = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      setErrorLoginAlert(errorsInitState);
    }
  }, [seconds, setErrorLoginAlert]);

  return (
    <>
      <span>{t('errorMessage.twoManyRequest')}</span> <span>{getTime()}</span>
    </>
  );
};

const getMessage = ({ errorCode, errorText, rateLimitRetryAfterSeconds, setErrorLoginAlert }: Props): ReactNode => {
  const errorMessage = errorMessages.get(errorCode);
  if (errorMessage) return errorMessage;
  if (rateLimitRetryAfterSeconds) {
    return (
      <TooManyRequest rateLimitRetryAfterSeconds={rateLimitRetryAfterSeconds} setErrorLoginAlert={setErrorLoginAlert} />
    );
  }

  return errorText;
};

export const LoginAlert = ({ errorCode, errorText, rateLimitRetryAfterSeconds, setErrorLoginAlert }: Props) => {
  const message = getMessage({ errorCode, errorText, rateLimitRetryAfterSeconds, setErrorLoginAlert });

  return <Alert message={message} type="error" showIcon className={style.styledAlert} />;
};
