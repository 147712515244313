import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { FormContainer } from 'components/common/forms/form-container';
import { Footer } from 'components/layout/footer';
import { PageRoute } from 'components/routing/utils';
import { recoveryRequest } from 'modules/api-requests/recoveryPassword';
import { BoldTitle } from 'components/common/BoldTitle';
import { Link } from 'components/common/link';
import { MondiadLogo } from 'components/common/MondiadLogo';
import { LanguageKeys } from 'modules/localization/entities';

import { ForgotPasswordForm, ForgotPasswordFormValues } from './ForgotPasswordForm';
import { useAlertBar } from './useAlertBar';

import style from './style.module.scss';

export const ForgotPasswordPage = () => {
  const { t, i18n } = useTranslation('forgotPassword');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>();

  const handleSubmit = useCallback(
    async (values: ForgotPasswordFormValues) => {
      const language = i18n.language as LanguageKeys;
      setIsLoading(true);
      setErrorText(undefined);
      setShowAlert(false);
      try {
        await recoveryRequest({ ...values, language });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data.message || err.message;
          setErrorText(errorMessage);
        }
      } finally {
        setShowAlert(true);
        setIsLoading(false);
      }
    },
    [i18n.language]
  );

  const onValuesChange = useCallback((values: ForgotPasswordFormValues) => {
    if ('email' in values) {
      setShowAlert(false);
    }
  }, []);
  const alertBar = useAlertBar({ errorText, showAlert });

  return (
    <Layout>
      <Layout.Content>
        <FormContainer oneColumn withMarginTop>
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <BoldTitle>{t('title')}</BoldTitle>
          <p>{t('subtitle')}</p>
          {alertBar}
          <ForgotPasswordForm onSubmit={handleSubmit} onValuesChange={onValuesChange} isLoading={isLoading} />
          <Link to={PageRoute.login} className={style.goToSignLink}>
            <i className="fas fa-arrow-left" style={{ marginRight: 5, lineHeight: 1.3 }} />
            {t('goToSign')}
          </Link>
        </FormContainer>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};
