import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import { InputProps } from 'antd/es/input';
import { FormItemProps } from 'antd/lib/form/FormItem';

import i18n from 'modules/localization';
import { PASSWORD_VALIDATION_PATTERN, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'components/const';
import { withoutSpace } from 'utils/form';
import { confirmedFieldValidator, confirmValidator, confirmedOnChangeValidator } from './validators';

type Props = FormItemProps & {
  label?: string;
  fieldName?: string;
  autoComplete?: string;
  hasConfirmValidator?: boolean;
  inputProps?: InputProps;
};

const passwordValidationRules: Rule[] = [
  {
    required: true,
    message: i18n.t('common:password.required'),
    validateTrigger: 'onBlur',
  },
  {
    pattern: PASSWORD_VALIDATION_PATTERN,
    message: i18n.t('common:password.pattern'),
    validateTrigger: 'onBlur',
  },
  {
    min: MIN_PASSWORD_LENGTH,
    message: i18n.t('common:password.minLength', { length: MIN_PASSWORD_LENGTH }),
    validateTrigger: 'onBlur',
  },
  { max: MAX_PASSWORD_LENGTH, validateTrigger: 'onBlur' },
  { validator: withoutSpace, message: i18n.t('common:password.noSpaceValidator') },
];

export const PasswordFormItem = ({
  label = i18n.t('common:password.label'),
  fieldName = 'password',
  autoComplete,
  hasConfirmValidator = true,
  inputProps,
  ...props
}: Props) => {
  const rules = hasConfirmValidator
    ? [
        ...passwordValidationRules,
        confirmedFieldValidator('confirm_password'),
        confirmedOnChangeValidator('confirm_password'),
      ]
    : passwordValidationRules;

  return (
    <Form.Item
      validateFirst
      label={label}
      name={fieldName}
      validateTrigger={['onBlur', 'onChange']}
      rules={rules}
      {...props}
    >
      <Input.Password type="password" autoComplete={autoComplete} {...inputProps} />
    </Form.Item>
  );
};

export const PasswordConfirmFormItem = ({
  label = i18n.t('common:confirmPassword.label'),
  autoComplete = 'new-password',
  inputProps,
  ...props
}: Props) => (
  <Form.Item
    validateFirst
    label={label}
    name="confirm_password"
    validateTrigger={['onBlur', 'onChange']}
    rules={[...passwordValidationRules, confirmValidator('password')]}
    {...props}
  >
    <Input.Password type="password" autoComplete={autoComplete} {...inputProps} />
  </Form.Item>
);
