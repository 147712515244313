import { Helmet } from 'react-helmet-async';
import { Button, Layout, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { FormContainer } from 'components/common/forms/form-container';
import { Loader } from 'components/common/loader';
import { Footer } from 'components/layout/footer';
import { getCookie } from 'utils/cookie';
import { PageRoute } from 'components/routing/utils';
import { MondiadLogo } from 'components/common/MondiadLogo';

export const RegistrationConfirmPage = () => {
  const { t } = useTranslation('registrationConfirm');
  const email = getCookie('registerEmail');
  const subTitle = (
    <div>
      <p>
        {t('subtitle.0')} {email}. {t('subtitle.1')}
      </p>
      <p>{t('subtitle.2')}</p>
    </div>
  );

  if (!email) {
    return (
      <FormContainer>
        <Navigate to={PageRoute.login} />
        <Loader />
      </FormContainer>
    );
  }

  return (
    <Layout>
      <Layout.Content>
        <FormContainer withMarginTop>
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <Result
            status="success"
            title={t('title')}
            subTitle={subTitle}
            icon={<i className="far fa-envelope" style={{ fontSize: 72 }} />}
            extra={[
              <Button href={PageRoute.login} key="login">
                {t('loginButton')}
              </Button>,
            ]}
          />
        </FormContainer>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};
