import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import { Loader } from 'components/common/loader';
import { ConfirmEmailRequestData } from 'modules/api-requests/authorization/entities';
import { PageRoute } from 'components/routing/utils';
import { deleteCookie } from 'utils/cookie';
import { LanguageKeys } from 'modules/localization/entities';
import { EmailConfirmation } from './EmailConfirmation';

type Props = {
  onRequest: (data: ConfirmEmailRequestData) => Promise<void>;
};

export const ConfirmEmail = ({ onRequest }: Props) => {
  const { t, i18n } = useTranslation('confirmEmail');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const token = (query.token as string) || '';
  const language = i18n.language as LanguageKeys;

  useEffect(() => {
    setIsLoading(true);
    onRequest({ token, language })
      .then(() => {
        deleteCookie('registerEmail');
      })
      .catch((err) => {
        const description = err?.fieldsErrors?.token || t('tokenInvalidDefaultError');

        navigate(PageRoute.login);
        notification.warn({
          message: t('confirmationEmailError'),
          description,
        });
      })
      .finally(() => setIsLoading(false));
  }, [language, navigate, onRequest, t, token]);

  if (isLoading) {
    return <Loader />;
  }

  return <EmailConfirmation />;
};
