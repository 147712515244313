import { ReactNode } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';

import style from './style.module.scss';

type Props = FormProps & {
  children?: ReactNode;
};
export const AuthStyledForm = (props: Props) => <Form className={style.authForm} {...props} />;
