import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Input, Row } from 'antd';

import { PhoneFormItem } from 'components/common/forms/phone';
import { NAME_VALIDATION_PATTERN } from 'utils/form';
import { SelectOption } from 'utils/getOptionsFromIdName';
import { r } from 'utils/r';
import { messengerIdMaxLength } from '../utils';
import { CountriesSelect } from './CountriesSelect';
import { MessengersSelect } from './MessengersSelect';
import { SourceInfoAboutUs } from './SourceInfoAboutUs';

const nameMaxLength = 255;

type Props = {
  countryCode?: string;
  countriesOptions: SelectOption[];
  messengersOptions: SelectOption[];
};

export const ProfileForm = ({ countryCode, countriesOptions, messengersOptions }: Props) => {
  const { t } = useTranslation('registration');

  return (
    <>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Form.Item
            name="firstName"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: t('registerForm.firstNameField.required') },
              { max: nameMaxLength, message: t('registerForm.firstNameField.max', { length: nameMaxLength }) },
              { pattern: NAME_VALIDATION_PATTERN, message: t('registerForm.namePattern') },
            ]}
          >
            <Input type="text" placeholder={r(t('registerForm.firstNameField.placeholder'))} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="lastName"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: t('registerForm.lastNameField.required') },
              { max: nameMaxLength, message: t('registerForm.lastNameField.max', { length: nameMaxLength }) },
              { pattern: NAME_VALIDATION_PATTERN, message: t('registerForm.namePattern') },
            ]}
          >
            <Input type="text" placeholder={r(t('registerForm.lastNameField.placeholder'))} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Form.Item name="countryId" rules={[{ required: true, message: t('registerForm.countrySelect.required') }]}>
            <CountriesSelect options={countriesOptions} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <PhoneFormItem country={countryCode} label="" inputProps={{ placeholder: t('registerForm.phone') }} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <Row gutter={4}>
            <Col sm={16} xs={24}>
              <Form.Item
                name="messengerId"
                rules={[
                  { required: true, message: t('registerForm.messengerIdField.required') },
                  {
                    max: messengerIdMaxLength,
                    message: t('registerForm.messengerIdField.max', { length: messengerIdMaxLength }),
                  },
                  { whitespace: true, message: t('registerForm.messengerIdField.whitespace') },
                ]}
              >
                <Input type="text" placeholder={r(t('registerForm.messengerIdField.placeholder'))} />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                name="messengerTypeId"
                rules={[{ required: true, message: t('registerForm.messengerTypeIdField.required') }]}
              >
                <MessengersSelect options={messengersOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name="sourceInfoAboutUs">
            <SourceInfoAboutUs />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item name="singUpForNewsletter" valuePropName="checked" noStyle>
          <Checkbox>{t('registerForm.singUpForNewsletter')}</Checkbox>
        </Form.Item>
      </Row>
    </>
  );
};
