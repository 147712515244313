import { LanguageSelector } from 'components/common/LanguageSelector';

import style from './style.module.scss';

export const Header = () => {
  return (
    <div className={style.header}>
      <LanguageSelector />
    </div>
  );
};
