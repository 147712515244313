import { useEffect, useState } from 'react';
import { notification } from 'antd';

import i18n from 'modules/localization';
import { getOptionsFromIdName, SelectOption } from 'utils/getOptionsFromIdName';
import { getCountriesDictionary } from 'modules/api-requests/dictionary';

type ErrorHandler = () => void;

const defaultErrorHandler: ErrorHandler = () =>
  notification.warn({
    message: i18n.t('common:countriesLoadingError.message'),
    description: i18n.t('common:countriesLoadingError.description'),
  });

type Props = {
  errorHandler?: ErrorHandler;
};

export function useCountriesOptions({ errorHandler = defaultErrorHandler }: Props = {}) {
  const [countriesOptions, setCountriesOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCountriesDictionary()
      .then(({ data: { data: countries } }) => setCountriesOptions(getOptionsFromIdName(countries)))
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, [errorHandler]);

  return { countriesOptions, isLoading };
}
