import React from 'react';

type Props = React.HTMLAttributes<HTMLImageElement> & {
  code: string;
};

export const Flag = ({ code, ...rest }: Props) => {
  // images from https://github.com/hjnilsson/country-flags
  const path = `/img/flags/${String(code).toLowerCase()}.svg`;
  return <img {...rest} src={path} alt={code} />;
};
