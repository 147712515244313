import { useEffect, useState } from 'react';
import { Alert, Form, Input, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';
import { TwoFactorType } from 'modules/api-requests/authorization/entities';
import { resendEmailTwoFaCode } from 'modules/api-requests/authorization';
import { useEmailClick } from 'utils/hooks/useEmailClick';
import { LoginFormValues } from '../LoginForm';

import style from '../style.module.scss';
import { LanguageKeys } from 'modules/localization/entities';
import i18n from 'modules/localization';

const getTwoFaAlert = (totpType: TwoFactorType): string => {
  if (totpType === TwoFactorType.app) return i18n.t('login:twoFAForm.appAlert');
  if (totpType === TwoFactorType.email) return i18n.t('login:twoFAForm.emailAlert');
  return i18n.t('login:twoFAForm.newDeviceAlert');
};

export type TwoFAFormValues = {
  totpCode: string;
};

type Props = {
  onSubmit: (values: TwoFAFormValues) => void;
  onValuesChange: (values: Partial<TwoFAFormValues>) => void;
  onBackButtonClick: () => void;
  totpType: TwoFactorType;
  loading: boolean;
  loginFormValues: LoginFormValues;
  resetTwoFATimer: () => void;
  deviceFingerprint: string;
};

export const TwoFAForm = ({
  onSubmit,
  onValuesChange,
  onBackButtonClick,
  totpType,
  loading,
  loginFormValues,
  resetTwoFATimer,
  deviceFingerprint,
}: Props) => {
  const { t, i18n } = useTranslation('login');
  const language = i18n.language as LanguageKeys;
  const [resendClicksCount, setResendClicksCount] = useState(0);
  const [shouldShowResendButton, setShowResendButton] = useState(false);
  const [shouldShowSupportMessage, setShowSupportMessage] = useState(false);
  const [isSending, setSending] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onResendClick = async () => {
    if (!executeRecaptcha) return;
    setResendClicksCount((resendClicksCount) => resendClicksCount + 1);
    setSending(true);
    const captchaCode = await executeRecaptcha();
    try {
      await resendEmailTwoFaCode({
        email: loginFormValues.email,
        password: loginFormValues.password,
        captchaCode,
        deviceFingerprint,
        language,
      });
      resetTwoFATimer();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notification.error({
          message: t('Error when sending email'),
          description: e.response?.data.message,
        });
      }
    } finally {
      setSending(false);
    }
    if (resendClicksCount >= 1) {
      setShowResendButton(false);
      setShowSupportMessage(true);
    }
  };
  const emailClick = useEmailClick();

  useEffect(() => {
    if (totpType !== 'Email') return;
    const timerId = setTimeout(() => setShowResendButton(true), 60000);
    return () => clearTimeout(timerId);
  }, [totpType]);

  return (
    <AuthStyledForm layout="vertical" onFinish={onSubmit} onValuesChange={onValuesChange} size="large">
      <div className={style.twoFaButtons}>
        <Button onClick={onBackButtonClick} className={style.backButton}>
          <i className="fas fa-long-arrow-alt-left mr-2" />
          {t('twoFAForm.backButton')}
        </Button>
        {shouldShowResendButton && (
          <Button onClick={onResendClick} loading={isSending}>
            <i className="fas fa-arrow-rotate-left mr-2" />
            {t('twoFAForm.resendButton')}
          </Button>
        )}
      </div>
      {shouldShowResendButton && <p>{t('twoFAForm.resendMessage')}</p>}
      {shouldShowSupportMessage && (
        <p>
          {t('twoFAForm.supportMessage')}{' '}
          <a target="_blank" href="mailto:contact@mondiad.com" onClick={emailClick} rel="noreferrer">
            contact@mondiad.com
          </a>
        </p>
      )}
      <div>
        <Alert message={getTwoFaAlert(totpType)} type="info" />
      </div>
      <Form.Item
        label={
          totpType === TwoFactorType.newDevice
            ? t('twoFAForm.totpCodeField.labelNewDevice')
            : t('twoFAForm.totpCodeField.labelTwoFa')
        }
        name="totpCode"
      >
        <Input
          placeholder={t('twoFAForm.totpCodeField.placeholder')}
          addonBefore={
            totpType === TwoFactorType.app ? <i className="fas fa-mobile-alt" /> : <i className="far fa-envelope" />
          }
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          return (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!Boolean(getFieldValue('totpCode'))}
              block
              className={style.action}
            >
              {t('twoFAForm.loginButton')}
            </Button>
          );
        }}
      </Form.Item>
    </AuthStyledForm>
  );
};
