import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormItemProps } from 'antd/lib/form/FormItem';
import { Rule } from 'rc-field-form/lib/interface';
import i18n from 'modules/localization';
import { r } from 'utils/r';

import { confirmValidator, confirmedFieldValidator, confirmedOnChangeValidator } from './validators';

type Props = FormItemProps & {
  label?: string;
  fieldName?: string;
  autoComplete?: string;
  additionalRules?: Rule[];
};

export const EmailFormItem = ({
  label = i18n.t('registration:registerForm.email.label'),
  fieldName = 'email',
  autoComplete = 'email',
  additionalRules,
  ...props
}: Props) => {
  const { t } = useTranslation('registration');
  return (
    <Form.Item
      label={label}
      name={fieldName}
      validateFirst
      validateTrigger={['onBlur', 'onChange']}
      rules={[
        {
          type: 'email',
          message: t('registerForm.email.pattern'),
          validateTrigger: 'onBlur',
        },
        {
          required: true,
          message: t('registerForm.email.required'),
          validateTrigger: 'onBlur',
        },
        confirmedFieldValidator('confirm_email'),
        confirmedOnChangeValidator('confirm_email'),
        ...(additionalRules || []),
      ]}
      {...props}
    >
      <Input autoComplete={autoComplete} placeholder={r(t('registerForm.email.placeholder'))} />
    </Form.Item>
  );
};

export const ConfirmEmailFormItem = ({
  label = i18n.t('registration:registerForm.confirmEmail.label'),
  fieldName = 'confirm_email',
  autoComplete = 'off',
  additionalRules,
  ...props
}: Props) => {
  const { t } = useTranslation('registration');
  return (
    <Form.Item
      label={label}
      name={fieldName}
      validateFirst
      validateTrigger={['onBlur', 'onChange']}
      rules={[
        {
          type: 'email',
          message: t('registerForm.email.pattern'),
          validateTrigger: 'onBlur',
        },
        {
          required: true,
          message: t('registerForm.confirmEmail.required'),
          validateTrigger: 'onBlur',
        },
        confirmValidator('email'),
        ...(additionalRules || []),
      ]}
      {...props}
    >
      <Input autoComplete={autoComplete} placeholder={r(t('registerForm.confirmEmail.placeholder'))} />
    </Form.Item>
  );
};
