import { BackTop, Button, Layout, Typography } from 'antd';

import { useEmailClick } from 'utils/hooks/useEmailClick';
import { FormContainer } from 'components/common/forms/form-container';
import { Agreement } from './Agreement';
import { AgreementMenu } from './AgreementMenu';
import React, { useState } from 'react';
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

export const TermsPage = () => {
  const [expanded, setExpanded] = useState(true);
  const menuStyle: React.CSSProperties = {
    paddingTop: '50px',
  };
  const buttonStyle: React.CSSProperties = {
    position: 'fixed',
    top: '10px',
    left: '20px',
    zIndex: '100',
  };
  return (
    <Layout>
      {expanded ? (
        <Layout.Sider width={250}>
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size="large"
            style={buttonStyle}
            onClick={() => setExpanded(false)}
          />
          <AgreementMenu style={menuStyle} />
        </Layout.Sider>
      ) : (
        <Button
          shape="circle"
          icon={<MenuOutlined />}
          size="large"
          style={buttonStyle}
          onClick={() => setExpanded(true)}
        />
      )}
      <Layout.Content>
        <FormContainer>
          <BackTop />
          <Typography>
            <a id="terms-and-conditions" />
            <Title>Terms and Conditions</Title>
            <Paragraph>
              <Text> This site is owned and operated by: </Text> <br /> <br />
              <Text strong>First Media Ltd</Text> <br />
              <Text>Eurotowers,</Text> <br />
              <Text>Gibraltar GX11 1AA,</Text> <br />
              <Text>Gibraltar</Text> <br /> <br />
              <Text>
                For any inquiries, you can contact us directly through email at{' '}
                <a target="_blank" href="mailto:contact@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
                  contact@mondiad.com
                </a>
                .
              </Text>
            </Paragraph>
            <Paragraph>
              By using our services, you are agreeing to our Terms and Conditions, so please read them carefully. <br />
              <a href="#advertiser">Advertiser Agreement</a> | <a href="#publisher">Publisher Agreement</a>
            </Paragraph>
          </Typography>
          <Agreement />
        </FormContainer>
      </Layout.Content>
    </Layout>
  );
};
