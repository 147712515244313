import queryString from 'query-string';

type Params = {
  [name: string]: string;
};

export const redirect = (url: string, params?: Params) => {
  if (params) {
    window.location.replace(`${url}?${queryString.stringify(params)}`);
    return;
  }
  window.location.replace(url);
};
