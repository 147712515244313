import common from './common.json';
import login from './login.json';
import registration from './registration.json';
import registrationConfirm from './registrationConfirm.json';
import forgotPassword from './forgotPassword.json';
import confirmEmail from './confirmEmail.json';
import newPassword from './newPassword.json';
import footer from './footer.json';

export const ES = {
  common,
  login,
  registration,
  registrationConfirm,
  forgotPassword,
  confirmEmail,
  newPassword,
  footer,
};
