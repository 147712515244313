import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Alert } from 'antd';

import style from '../style.module.scss';

type Props = {
  emailWasSent: boolean;
  closeModal: () => void;
  resendEmail: () => Promise<void>;
};
export enum RequestStatusEnum {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export function useExtraBlock({ emailWasSent, closeModal, resendEmail }: Props) {
  const { t } = useTranslation('login');
  const [requestStatus, setRequestStatus] = useState<RequestStatusEnum>();
  const resendEmailAndChangeStatus = useCallback(() => {
    setRequestStatus(RequestStatusEnum.LOADING);
    resendEmail()
      .then(() => setRequestStatus(RequestStatusEnum.SUCCESS))
      .catch(() => setRequestStatus(RequestStatusEnum.FAIL));
  }, [resendEmail]);
  const isLoading = requestStatus === RequestStatusEnum.LOADING;
  const isSuccess = requestStatus === RequestStatusEnum.SUCCESS;

  if (emailWasSent) {
    return <Button onClick={closeModal}>{t('newUserModal.button.close')}</Button>;
  }

  if (isSuccess) {
    return (
      <>
        <Alert message={t('newUserModal.alert.success')} type="success" showIcon className={style.styledAlert} />
        <Button onClick={closeModal}>{t('newUserModal.button.close')}</Button>
      </>
    );
  }

  return (
    <Button type="primary" onClick={resendEmailAndChangeStatus} loading={isLoading}>
      {t('newUserModal.button.resend')}
    </Button>
  );
}
