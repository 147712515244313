import { useTranslation, Trans } from 'react-i18next';
import { Modal, Result } from 'antd';

import { useExtraBlock } from './useExtraBlock';

type Props = {
  visible: boolean;
  emailWasSent: boolean;
  closeModal: () => void;
  resendEmail: () => Promise<void>;
};

export const NewUserModal = ({ visible, closeModal, emailWasSent, resendEmail }: Props) => {
  const { t } = useTranslation('login');

  const extraBlock = useExtraBlock({
    emailWasSent,
    closeModal,
    resendEmail,
  });

  return (
    <Modal open={visible} footer={null} closable={false} maskTransitionName="">
      <Result
        status="warning"
        title={t('newUserModal.title')}
        subTitle={
          emailWasSent ? (
            <Trans i18nKey="login:newUserModal.text.hasGotLink">
              Please follow the activation link that has been sent to your email provided during account registration.
              <br />
              <br />
              The activation link's lifetime is 24 hours. After that you will be able to request another email with a
              new one.
            </Trans>
          ) : (
            <Trans i18nKey="login:newUserModal.text.canSendLink">
              Please follow the activation link that has been sent to your email provided during account registration.
              <br />
              <br />
              If you hasn't received the activation link, press the button below and we will send you the activation
              link once again.
            </Trans>
          )
        }
        extra={extraBlock}
      />
    </Modal>
  );
};
