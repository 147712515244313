import { httpClient } from 'httpClient';
import { apiVersion, authHost as host } from '../const';
import { ResponseAPI } from '../entities';
import { RegisterRequest, RegisterResponse } from './entities';

type RegisterRequestType = (
  data: RegisterRequest,
  isPublisher: boolean | undefined
) => Promise<ResponseAPI<RegisterResponse>>;

export const registerRequest: RegisterRequestType = (data, isPublisher) =>
  httpClient.post(`${host}/api/${apiVersion}/auth/register${isPublisher ? '/publisher' : ''}`, data);
