import { httpClient } from 'httpClient';
import { authHost, apiVersion } from 'modules/api-requests/const';
import { ResponseAPI } from '../entities';

import {
  LoginRequestData,
  AuthToken,
  Country,
  ResendActivationEmailRequestData,
  ConfirmEmailRequestData,
} from './entities';
import { LanguageKeys } from 'modules/localization/entities';

export const loginRequest = ({ deviceFingerprint, ...data }: LoginRequestData): Promise<ResponseAPI<AuthToken>> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/login`, data, {
    headers: {
      'x-device-fingerprint': deviceFingerprint,
    },
  });

export const getGeoCountryRequest = (): Promise<ResponseAPI<Country>> =>
  httpClient.post(`${authHost}/api/${apiVersion}/geo/country`);

export const resendConfirmEmailRequest = (data: ResendActivationEmailRequestData): Promise<void> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/email/confirm/resend`, data);

export const confirmEmailRequest = (data: ConfirmEmailRequestData): Promise<void> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/email/confirm`, data);

export const confirmChangeEmailRequest = (data: ConfirmEmailRequestData): Promise<void> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/email/change/confirm`, data);

export const getRedirectUrlRequest = (data: { token: string }): Promise<ResponseAPI<{ redirectUrl?: string }>> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/get-redirect-url`, data);

type ResendEmailTwoFaCodeData = {
  email: string;
  password: string;
  captchaCode: string;
  deviceFingerprint: string;
  language?: LanguageKeys;
};

export const resendEmailTwoFaCode = ({
  deviceFingerprint,
  ...data
}: ResendEmailTwoFaCodeData): Promise<ResponseAPI<void>> => {
  return httpClient.post(`${authHost}/api/${apiVersion}/auth/resend-email-totp`, data, {
    headers: {
      'x-device-fingerprint': deviceFingerprint,
    },
  });
};
