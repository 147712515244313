import { useEffect } from 'react';

const paramName = 'src';
const storageKey = 'source';

// if the url contains a source, save it. Url like ...?src=telegram
export const useSourceFrom = () => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const source = params.get(paramName);
    if (source) {
      localStorage.setItem(storageKey, source);
    }
  }, []);
};

export const getSource = () => localStorage.getItem(storageKey);
export const clearSource = () => localStorage.removeItem(storageKey);
