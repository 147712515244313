import { useEffect, useCallback } from 'react';
import { notification } from 'antd';
import { setTimeout, clearTimeout } from 'worker-timers';
import { useTranslation } from 'react-i18next';
import { TwoFactorType } from 'modules/api-requests/authorization/entities';

const initTimeout = 300;
let timerId: number;
export function useTwoFATimer(setTotpType: (type?: TwoFactorType) => void) {
  const { t } = useTranslation('login');

  const reset = useCallback(() => {
    setTotpType();
    notification.warn({ message: t('twoFAForm.authCodeInvalidWarning') });
  }, [setTotpType, t]);

  const startTwoFATimer = useCallback(
    (time = initTimeout) => {
      timerId = setTimeout(reset, time * 1000);
    },
    [reset]
  );

  const resetTwoFATimer = useCallback(() => {
    clearTimeout(timerId);
    startTwoFATimer();
  }, [startTwoFATimer]);

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []);

  return { startTwoFATimer, resetTwoFATimer };
}
