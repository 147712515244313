import { useRef, useState } from 'react';
import { Button, Checkbox, Col, Form, Row, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioChangeEvent } from 'antd/es/radio';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import { PasswordConfirmFormItem, PasswordFormItem } from 'components/common/forms/password';
import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';
import { Link } from 'components/common/link';
import { PageRoute } from 'components/routing/utils';
import { setErrors } from 'utils/form';
import { ConfirmEmailFormItem, EmailFormItem } from 'components/common/forms/email';
import { serverErrorValidator } from 'components/common/forms/validators';
import useServerErrors from 'utils/hooks/useServerErrors';
import { RegisterRequest } from 'modules/api-requests/registration/entities';
import { FieldErrors } from 'modules/api-requests/entities';
import { SelectOption } from 'utils/getOptionsFromIdName';
import { r } from 'utils/r';
import i18n from 'modules/localization';

import { ProfileForm } from './components/ProfileForm';
import { CompanyProfileForm } from './components/CompanyProfileForm';

export type RegisterFormValues = Omit<RegisterRequest, 'referral' | 'source'> & {
  agreement: boolean;
  // eslint-disable-next-line camelcase
  confirm_email: string;
  // eslint-disable-next-line camelcase
  confirm_password: string;
};

type Props = {
  handleSubmit: (data: RegisterFormValues) => void;
  initialValues: Partial<RegisterFormValues>;
  clearError: () => void;
  countriesOptions: SelectOption[];
  messengersOptions: SelectOption[];
  isLoading?: boolean;
  errorText: string;
  errorFields?: FieldErrors;
};

const accountTypeOptions = [
  { label: i18n.t('registration:registerForm.accountTypeField.individual'), value: 'individual' },
  { label: i18n.t('registration:registerForm.accountTypeField.company'), value: 'company' },
];

export const RegisterForm = ({
  handleSubmit,
  initialValues,
  isLoading,
  messengersOptions,
  countriesOptions,
  clearError,
  errorText,
  errorFields,
}: Props) => {
  const { t } = useTranslation('registration');
  const [accountType, setAccountType] = useState('individual');
  const submitValuesBackup = useRef<RegisterFormValues>();
  const [form] = Form.useForm<RegisterFormValues>();
  const isAgreed = Form.useWatch('agreement', form);

  const onSubmit = (values: RegisterFormValues) => {
    submitValuesBackup.current = values;
    handleSubmit(submitValuesBackup.current);
  };

  const onFinishFailed = (formValues: ValidateErrorEntity) => {
    if (errorFields && errorFields.length && submitValuesBackup.current) {
      const filteredErrorFields: { [key: string]: string } = {};
      (Object.keys(errorFields) as Array<keyof RegisterFormValues>).forEach((key) => {
        if (submitValuesBackup.current && submitValuesBackup.current[key] === formValues.values[key]) {
          filteredErrorFields[key] = errorFields[key];
        }
      });
      setErrors(form, filteredErrorFields);
    }
  };

  const onChangeAccountType = (e: RadioChangeEvent) => setAccountType(e.target.value);

  useServerErrors({
    form,
    clearError,
    errorFields: errorFields || {},
    errorText,
  });

  const emailAndPasswordFields = (
    <>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <EmailFormItem
            label=""
            autoComplete="new-email"
            additionalRules={[serverErrorValidator('email', submitValuesBackup, errorFields)]}
          />
        </Col>
        <Col span={24} md={12}>
          <ConfirmEmailFormItem label="" autoComplete="new-confirm-email" />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <PasswordFormItem
            autoComplete="new-password"
            label=""
            inputProps={{ placeholder: r(t('registerForm.passwordPlaceholder')) }}
          />
        </Col>
        <Col span={24} md={12}>
          <PasswordConfirmFormItem
            autoComplete="new-confirm-password"
            label=""
            inputProps={{ placeholder: r(t('registerForm.confirmPasswordPlaceholder')) }}
          />
        </Col>
      </Row>
    </>
  );
  const countryCode = String(initialValues?.countryId).toLocaleLowerCase();

  return (
    <AuthStyledForm
      name="register_form"
      initialValues={initialValues}
      form={form}
      layout="horizontal"
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="new-password"
      scrollToFirstError
      size="large"
    >
      <Row>
        <Col span={24}>
          <Form.Item label={t('registerForm.accountTypeField.label')}>
            <Radio.Group options={accountTypeOptions} value={accountType} onChange={onChangeAccountType} />
          </Form.Item>
        </Col>
      </Row>
      {accountType === 'individual' ? (
        <>
          {emailAndPasswordFields}
          <ProfileForm
            countryCode={countryCode}
            countriesOptions={countriesOptions}
            messengersOptions={messengersOptions}
          />
        </>
      ) : (
        <CompanyProfileForm
          countryCode={countryCode}
          emailAndPasswordFields={emailAndPasswordFields}
          countriesOptions={countriesOptions}
          messengersOptions={messengersOptions}
        />
      )}
      <Form.Item>
        <Form.Item name="agreement" valuePropName="checked" noStyle>
          <Checkbox>{t('registerForm.agreementCheckbox')}</Checkbox>
        </Form.Item>
        <Link target="_blank" to={PageRoute.terms}>
          {t('registerForm.termsOfUse')}
        </Link>
        {', '}
        <Link target="_blank" to={PageRoute.cookie}>
          {t('registerForm.cookiePolicy')}
        </Link>
        {', '}
        <Link target="_blank" to={PageRoute.privacy}>
          {t('registerForm.privacyPolicy')}
        </Link>
      </Form.Item>

      <Row justify="center">
        <Col xs={24} md={24}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!isAgreed}
            className="w-100"
            style={{ marginBottom: 35 }}
          >
            {t('registerForm.registerButton')}
          </Button>
        </Col>
      </Row>
    </AuthStyledForm>
  );
};
