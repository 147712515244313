import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { EN, RU, ES } from './locales';
import { LanguageKeys } from './entities';

const allowKeys = Object.values(LanguageKeys);

const detection = {
  caches: ['localStorage'],
  convertDetectedLanguage: (lng: string) => {
    const lngKey = lng.split('-')[0].toUpperCase() as LanguageKeys;
    return allowKeys.includes(lngKey) ? lngKey : LanguageKeys.EN;
  },
};

/**
 * ISO 639
 * https://www.iso.org/iso-639-language-codes.html
 * http://www.loc.gov/standards/iso639-2/php/code_list.php
 */
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: { EN, RU, ES },
    fallbackLng: LanguageKeys.EN,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection,
  });

export default i18n;
