import { useTranslation } from 'react-i18next';

import { FormContainer } from 'components/common/forms/form-container';
import { Link } from 'components/common/link';
import { PageRoute } from 'components/routing/utils';

import style from './style.module.scss';

export const InvalidTokenScreen = () => {
  const { t } = useTranslation('newPassword');

  return (
    <FormContainer oneColumn>
      <h2>{t('invalidToken.title')}</h2>
      <div className={style.container}>
        <div style={{ marginBottom: '2px' }}>{t('invalidToken.subtitle')}</div>
        <div>
          <Link underlined to={PageRoute.login}>
            {t('invalidToken.loginLink')}
          </Link>{' '}
          {t('invalidToken.or')}{' '}
          <Link underlined to={PageRoute.forgotPassword}>
            {t('invalidToken.resetAgainLink')}
          </Link>
        </div>
      </div>
    </FormContainer>
  );
};
