export const SIDEBAR_WIDTH = 256;
export const SIDEBAR_WIDTH_MIN = 216;
export const COLLAPSED_SIDEBAR_WIDTH = 80;

export const PASSWORD_VALIDATION_PATTERN = /((?=.*[@$!%*?&]))(?=(.*[A-z])+)(?=(.*\d)+)/;
export const NO_SPEC_SYMBOLS_VALIDATION_PATTERN = /^[A-Za-z0-9_-]+$/;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 250;

export const PHONE_CODES = [
  93, 355, 213, 376, 244, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 975, 591, 387, 267,
  55, 246, 673, 359, 226, 257, 855, 237, 1, 238, 599, 236, 235, 56, 86, 57, 269, 243, 242, 506, 225, 385, 53, 599, 357,
  420, 45, 253, 1767, 1, 593, 20, 503, 240, 291, 372, 251, 679, 358, 33, 594, 689, 241, 220, 995, 49, 233, 30, 1473,
  590, 1671, 502, 224, 245, 592, 509, 504, 852, 36, 354, 91, 62, 98, 964, 353, 972, 39, 1876, 81, 962, 7, 254, 686, 383,
  965, 996, 856, 371, 961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222, 230, 52,
  691, 373, 377, 976, 382, 212, 258, 95, 264, 674, 977, 31, 687, 64, 505, 227, 234, 850, 47, 968, 92, 680, 970, 507,
  675, 595, 51, 63, 48, 351, 1, 974, 262, 40, 7, 250, 1869, 1758, 1784, 685, 378, 239, 966, 221, 381, 248, 232, 65, 421,
  386, 677, 252, 27, 82, 211, 34, 94, 249, 597, 268, 46, 41, 963, 886, 992, 255, 66, 670, 228, 676, 1868, 216, 90, 993,
  688, 256, 380, 971, 44, 1, 598, 998, 678, 39, 58, 84, 967, 260, 263,
];

export const TERMS_COMPANY_NAME = 'First Media Ltd';
