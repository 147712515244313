import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  errorText?: string;
  showAlert: boolean;
};

export function useAlertBar({ errorText, showAlert }: Props) {
  const { t } = useTranslation('forgotPassword');
  const message = errorText ?? t('alertMessage');
  const type = errorText ? 'error' : 'success';

  if (!showAlert) {
    return null;
  }

  return <Alert message={message} style={{ marginBottom: 15 }} type={type} showIcon />;
}
