import { ReactElement } from 'react';

export enum LanguageKeys {
  EN = 'EN',
  RU = 'RU',
  ES = 'ES',
}

export type Language = {
  key: LanguageKeys;
  nativeName: string;
  ico: ReactElement;
};
