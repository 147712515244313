import { useCallback, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { PageRoute } from 'components/routing/utils';

export enum RoleTab {
  advertiser = 'advertiser',
  publisher = 'publisher',
}

export function useRoleTab() {
  const query = queryString.parse(window.location.search);
  const initTabRole = (query?.role as RoleTab) || RoleTab.advertiser;
  const [tab, setTab] = useState(initTabRole);
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (tabRole: string) => {
      setTab(tabRole as RoleTab);
      const search = queryString.stringify({
        role: tabRole,
      });
      navigate({
        pathname: PageRoute.registration,
        search: `?${search}`,
      });
    },
    [navigate]
  );

  return {
    tab,
    handleTabChange,
  };
}
