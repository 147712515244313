import { useEffect, useState } from 'react';
import { notification } from 'antd';

import i18n from 'modules/localization';
import { getOptionsFromIdName, SelectOption } from 'utils/getOptionsFromIdName';
import { getMessengersDictionary } from 'modules/api-requests/dictionary';
import { MessengerDictionary } from 'modules/api-requests/dictionary/entities';

type ErrorHandler = () => void;

const defaultErrorHandler: ErrorHandler = () =>
  notification.warn({
    message: i18n.t('common:messengersLoadingError.message'),
    description: i18n.t('common:messengersLoadingError.description'),
  });

const removeObsoleteMessengers = (messengers: MessengerDictionary) => messengers.filter(({ obsolete }) => !obsolete);

type Props = {
  errorHandler?: ErrorHandler;
};

export function useMessengersOptions({ errorHandler = defaultErrorHandler }: Props = {}) {
  const [messengersOptions, setMessengersOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getMessengersDictionary()
      .then(({ data: { data: messengers } }) =>
        setMessengersOptions(getOptionsFromIdName(removeObsoleteMessengers(messengers)))
      )
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  }, [errorHandler]);

  return { messengersOptions, isLoading };
}
