import { BackTop, Typography } from 'antd';

import { Link } from 'components/common/link';
import { FormContainer } from 'components/common/forms/form-container';
import { useEmailClick } from 'utils/hooks/useEmailClick';
import { PageRoute } from 'components/routing/utils';
import { TERMS_COMPANY_NAME } from 'components/const';

const { Title, Paragraph } = Typography;

const hostName = `${location.protocol}//${location.hostname}`;
const scrollToUp = () => window.scrollTo(0, 0);

export const PrivacyPage = () => {
  return (
    <FormContainer>
      <BackTop />

      <Typography>
        <Title>Privacy policy</Title>

        <Title level={2}>Acceptance of terms</Title>

        <Paragraph>
          <>{TERMS_COMPANY_NAME} </>collects personal information when you register. Upon registration, we ask for
          information such as name, e-mail address, instant messaging address and country. For some types of deposits,
          if a<> {TERMS_COMPANY_NAME} </>representative will consider necessary, we may request for additional evidence
          of ownership and authorisation for those transactions such as address, proof of residence or others, depending
          on the case.
        </Paragraph>

        <Paragraph>
          For transactions made from client to<> {TERMS_COMPANY_NAME}</>, the client can introduce billing info such as
          company name, address, country, tax ID, company number and other information for the purpose of downloading
          invoices.
        </Paragraph>

        <Paragraph>
          <>{TERMS_COMPANY_NAME} </>stores all communication content done between you and<> {TERMS_COMPANY_NAME} </>
          representatives, including e-mails and instant messaging conversations.
        </Paragraph>

        <Paragraph>
          <>{TERMS_COMPANY_NAME} </>uses the information provided for the following purposes: verify the authenticity of
          each of our users, authorisation of transaction and ownership of websites, offers and accounts.
        </Paragraph>

        <Paragraph>
          The data provided to<> {TERMS_COMPANY_NAME} </>will be used solely for<> {TERMS_COMPANY_NAME} </>activities
          and it does not include any 3rd party.
        </Paragraph>

        <Title level={2}>Sharing and disclosure</Title>

        <Paragraph>
          <>{TERMS_COMPANY_NAME} </>will not sell, rent or share personal information submitted through
          <> {TERMS_COMPANY_NAME} </>
          registration form or as requested by<> {TERMS_COMPANY_NAME} </>representatives, except under the following
          circumstances:
        </Paragraph>

        <ul>
          <li>
            We respond to subpoenas, court orders or legal processes (such as law enforcement requests), or to establish
            our legal rights or defend against legal claims.
          </li>

          <li>
            When we believe it is necessary to share information to investigate, prevent or stop any illegal activities,
            suspected fraud, situations involving potential threats to the physical safety of any person, violation of
            <> {TERMS_COMPANY_NAME}</>’s Terms of Service, or as otherwise required by law.
          </li>
        </ul>

        <Title level={2}>Cookies</Title>

        <Paragraph>
          Our Website uses cookies to distinguish you from other users of the Website and provide contractual
          obligations in a high quality level. This helps us to ensure its smooth operation in order to improve your
          experience. For detailed information on the cookies we use and the purposes for which we use them, please see
          our Cookie policy at{' '}
          <Link to={PageRoute.cookie} onClick={scrollToUp}>
            {hostName}/cookie
          </Link>
          .
        </Paragraph>

        <Title level={2}>Changes to this privacy policy</Title>

        <Paragraph>
          We may update this privacy policy from time to time by publishing a new version on our Website.We will notify
          you appropriately when we make changes to the privacy policy and we will amend its revision date so that you
          know when we last amended it. We do however encourage you to review this statement periodically so as to
          always be informed about how we are processing and protecting your personal information.We may notify you of
          changes to this policy by email or through the private messaging system on our Website.
        </Paragraph>

        <Title level={2}>Contact</Title>

        <Paragraph>
          If you have any questions about our privacy policy or wish to obtain more details in relation to the personal
          data we process about you, please contact us via email at:{' '}
          <a target="_blank" href="mailto:support@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
            support@mondiad.com
          </a>
          .
        </Paragraph>
      </Typography>
    </FormContainer>
  );
};
