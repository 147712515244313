import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export const useEmailClick = () => {
  const { t } = useTranslation('common');

  return () => {
    let timer: ReturnType<typeof setTimeout>;

    const onWindowBlur = () => {
      clearTimeout(timer);
      window.removeEventListener('blur', onWindowBlur);
    };

    timer = setTimeout(() => {
      notification.warning({
        message: t('emailClickWarning.message'),
        description: t('emailClickWarning.description'),
      });
      onWindowBlur();
    }, 1000);

    window.addEventListener('blur', onWindowBlur);
  };
};
