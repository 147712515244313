import { useEffect, useState } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const useFingerPrint = () => {
  const [deviceFingerprint, setDeviceFingerprint] = useState('');

  useEffect(() => {
    const fpPromise = FingerprintJS.load({
      monitoring: false,
    });
    fpPromise.then((fp) => fp.get()).then((result) => setDeviceFingerprint(result.visitorId));
  }, []);

  return { deviceFingerprint };
};
