import { Dropdown, MenuProps } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Flag } from '../Flag';

import { Language, LanguageKeys } from 'modules/localization/entities';

import styles from './style.module.scss';

const selectedKeys = ['0'];

const languagesList: Language[] = [
  { key: LanguageKeys.EN, nativeName: 'English', ico: <Flag code="gb" className={styles.flag} /> },
  {
    key: LanguageKeys.RU,
    nativeName: 'Russian',
    ico: <Flag code="ru" className={styles.flag} />,
  },
  {
    key: LanguageKeys.ES,
    nativeName: 'Spanish',
    ico: <Flag code="es" className={styles.flag} />,
  },
];

const getLanguage = (language?: string): string => {
  if (language?.includes('-')) return language.split('-')[0].toUpperCase();
  return language || LanguageKeys.EN;
};

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const activeLng = getLanguage(i18n.language);

  const items: MenuProps['items'] = languagesList.map((item) => {
    const onClickLng = () => {
      i18n.changeLanguage(item.key);
      moment.locale(item.key);
      window.location.reload();
    };
    return {
      key: item.key,
      onClick: onClickLng,
      label: (
        <div className={styles.language}>
          {item.ico}
          <span>{item.nativeName}</span>
        </div>
      ),
    };
  });

  return (
    <Dropdown menu={{ items, selectedKeys }}>
      <div className={styles.activeLng}>{activeLng}</div>
    </Dropdown>
  );
};
