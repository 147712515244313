import { BackTop, Typography } from 'antd';

import { FormContainer } from 'components/common/forms/form-container';
import { useEmailClick } from 'utils/hooks/useEmailClick';

const { Title, Paragraph, Text } = Typography;

export const CookiePage = () => (
  <FormContainer>
    <BackTop />
    <Typography>
      <Title>Cookie policy</Title>

      <Title level={2}>Consent</Title>

      <Paragraph>
        By using the website www.mondiad.com (the Website) and the services it provides to Advertisers and Publishers
        (the, “You”, “Your”, etc.), you consent to the use of cookies in accordance with this Cookie policy. The Website
        uses cookies to distinguish you from other users of the Website. This helps us to provide you with a good
        experience when you browse the website and also allows us to improve the Website.
      </Paragraph>

      <Title level={2}>Disabling cookies</Title>

      <Paragraph>
        If you do not agree to the use of these cookies please disable them by following the instructions for your
        browser set out here or use the automated disabling tools where available below. Please note that some of the
        services will not function so well if cookies are disabled.
      </Paragraph>

      <Paragraph>
        Where the organization setting the cookie provides an automated disabling tool in respect of its cookie(s) we
        list the name of that organization, the category of cookies it sets together with a link to its automated
        disabling tool. In all other cases, we list the names of the cookies themselves and their source at the date of
        this Cookie policy so that you can easily identify and disable them if you want through your browser controls.
      </Paragraph>

      <Paragraph>
        Some browsers make it possible for you to signal that you do not want your internet browsing activity to be
        tracked. Disabling tracking may interfere with your use of the Website and the services provided on the Website.
      </Paragraph>

      <Paragraph>
        After your initial visit to the Website, we may change the cookies we use. This Cookie policy will always allow
        you to know who is placing cookies, for what purpose and give you the means to disable them so you should check
        it from time to time.
      </Paragraph>

      <Title level={2}>What are cookies?</Title>

      <Paragraph>
        Cookies are text files containing small amounts of information which are downloaded to your device when you
        visit a website. Cookies are then sent back to the originating web domain on your subsequent visits to that
        domain. Most web pages contain elements from multiple web domains so when you visit the Website, your browser
        may receive cookies from several sources.
      </Paragraph>

      <Paragraph>
        Cookies are useful because they allow a website to recognize a user’s device. Cookies allow you to navigate
        between pages efficiently, remember preferences and generally improve the user experience. They can also be used
        to tailor advertising to your interests through tracking your browsing across websites.
      </Paragraph>

      <Paragraph>
        Session cookies are deleted automatically when you close your browser and persistent cookies remain on your
        device after the browser is closed (for example to remember your user preferences when you return to the site).
      </Paragraph>

      <Title level={2}>What types of cookies do we use?</Title>

      <Paragraph>
        We describe the categories of cookies which are used in Mondiad.com, its affiliates and its contractors use
        below.
      </Paragraph>

      <Title level={2}>Strictly Necessary Cookies</Title>

      <Paragraph>
        These cookies are essential in order to enable you to move around the website and use its features. Without
        these cookies, services you have asked for (such as navigating between pages) cannot be provided.
      </Paragraph>

      <Title level={2}>Performance Cookies</Title>

      <Paragraph>
        We make use of analytic cookies to analyze how our visitors use our Website and to monitor Website performance.
        This allows us to provide a high-quality experience by customizing our offering and quickly identifying and
        fixing any issues that arise. For example, we might use performance cookies to keep track of which pages are
        most popular, which method of linking between pages is most effective, and to determine why some pages are
        receiving error messages. We might also use these cookies to highlight articles or site services that we think
        will be of interest to you based on your usage of the Website. The information collected by these cookies is not
        associated with your personal information by us or by our contractors.
      </Paragraph>

      <Title level={2}>The Website currently uses the following analytic cookies:</Title>

      <Paragraph>
        Google Analytics, these cookies are used to collect information about how visitors use our site. We use the
        information to compile reports and to help us improve our site. The cookies collect information in an anonymous
        form, including the number of visitors to the site, where visitors have come to the site from and the pages they
        visited.
        <br />
        Click here for Google’s privacy policy in respect of Google Analytics:{' '}
        <a href="https://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noopener noreferrer">
          https://www.google.com/analytics/learn/privacy.html
        </a>
        <br />
        You may opt out of tracking by Google Analytics by visiting:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank" rel="noopener noreferrer">
          https://tools.google.com/dlpage/gaoptout?hl=en-GB
        </a>
        .
      </Paragraph>

      <Title level={2}>Website Functionality Cookies</Title>

      <Paragraph>
        We make use of cookies to provide you with certain functionality. For example, to remember choices you make
        (such as your user name, language or the region you are in), or to recognize the platform from which you access
        the site, and to provide enhanced and more personal features. These cookies are not used to track your browsing
        on other sites.
      </Paragraph>

      <Title level={2}>Session Cookies</Title>

      <Paragraph>
        These cookie files contain information about how users interact with the Website during the browsing session.
        This may be information about the frequently visited or most recently visited web pages and about visited
        sections of the Website, for example, if the user saw an error message. The Session cookies are used to provide
        and enhance a positive experience for website and service users.
      </Paragraph>

      <Paragraph>
        <Text strong>Google Analytics</Text> &mdash; web analytics service offered by Google that tracks and reports
        website traffic. We collect universal data (Google Client IDs, Sessions time, Page visits, etc.) on user
        sessions.
        <br />
        Read here:{' '}
        <a href="https://www.google.com/intl/ru/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">
          https://www.google.com/intl/ru/policies/privacy/partners/
        </a>
        .
      </Paragraph>

      <Paragraph>
        <Text strong>Hotjar</Text> &mdash; a product that allows to collects visual data on sessions (click, view and
        scroll heatmap and recorded web-sessions).
        <br />
        Read here:{' '}
        <a href="https://www.hotjar.com/legal/compliance/gdpr-commitment" target="_blank" rel="noopener noreferrer">
          https://www.hotjar.com/legal/compliance/gdpr-commitment
        </a>
        .
      </Paragraph>

      <Title level={2}>Persistent cookies</Title>

      <Paragraph>
        These cookies help Website remember information and settings applied by the user, such as language selection,
        theme selection, menu preferences, internal site bookmarks or favorites, and others. Persistent cookies enable a
        website to remember you on subsequent visits, speeding up or enhancing your experience of services or functions
        offered.
      </Paragraph>

      <Title level={2}>Use of IP addresses and weblogs</Title>

      <Paragraph>
        We may also use your IP address and browser type to help diagnose problems with our server, to administer our
        Website and to improve the service we offer to you. An IP address is a numeric code that identifies your
        computer on the internet. Your IP address might also be used to gather broad demographic information.
      </Paragraph>

      <Paragraph>
        We may perform IP lookups to determine which domain you are coming from (e.g. google.com) to more accurately
        gauge our users’ demographics.
      </Paragraph>

      <Paragraph>
        Information from these types of cookies and technologies or about website usage is not combined with information
        about you from any other source.
      </Paragraph>

      <Title level={2}>Cookie policy does not cover third party websites</Title>

      <Paragraph>
        Please note that this Cookie policy does not apply to, and we are not responsible for, the privacy practices of
        third party websites which may be linked to this Website. These cookies are likely to be analytical/performance
        cookies or advertising cookies.
      </Paragraph>

      <Title level={2}>Pixel tracking</Title>

      <Paragraph>
        Cookie files can be used to ensure proper display of advertising materials in campaigns of our advertisers and
        to measure the effectiveness of such campaigns. In this case, information may be collected through pixel
        tracking tags, which advertisers undertake to place at it's or its partners' sites.
      </Paragraph>

      <Paragraph>
        A pixel is a line of code which is used by a Website or third party ad server to track a user’s activity. The
        use of a pixel allows us to record that a user has visited a particular webpage along with additional
        non-personally identifiable information that the website publisher or advertiser may choose to include with the
        pixel tracking tags. We do not collect the online user’s personally identifiable information through our pixel
        tracking tags.
      </Paragraph>

      <Paragraph>
        These tags are used by our ads server regarding the user’s behavior and usage patterns on advertisements and
        sites operated by our clients to better customize the type of advertisements users see on various sites and
        refers only to non-personally identifiable information of users. We believe that the use of these pixel tracking
        tags enables us and our clients to provide users with a more meaningful web experience.
      </Paragraph>

      <Title level={2}>Changes to the Cookie policy</Title>

      <Paragraph>
        We may update this cookie policy and we would encourage you to review the policy from time to time to stay
        informed of how we are using cookies. Last update: 2021.03.01.
      </Paragraph>

      <Title level={2}>Questions or Comments about this Cookie Policy</Title>

      <Paragraph>
        If you have questions or concerns regarding this statement, you should first contact us through email. You can
        address your general questions and comments by e-mail to{' '}
        <a target="_blank" href="mailto:support@mondiad.com" onClick={useEmailClick()} rel="noreferrer">
          support@mondiad.com
        </a>
        .
      </Paragraph>
    </Typography>
  </FormContainer>
);
