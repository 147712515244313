import { IdType } from 'modules/api-requests/dictionary/entities';

type IdName = { id: IdType; name: string };
export type SelectOption = { value: IdType; label: string };

export function getOptionsFromIdName<T extends IdName>(values: T[]): SelectOption[] {
  return values.map((value) => ({
    value: value.id,
    label: value.name,
  }));
}
