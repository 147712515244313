import { Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';

import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';

import style from './style.module.scss';

export type LoginFormValues = {
  remember: boolean;
  email: string;
  password: string;
};

const defaultInitialValues = {
  remember: true,
};

type Props = {
  form: FormInstance;
  onSubmit: (values: LoginFormValues) => void;
  onValuesChange: (values: Partial<LoginFormValues>) => void;
  initialValues?: Partial<LoginFormValues>;
  loading: boolean;
};

export const LoginForm = ({ form, onSubmit, onValuesChange, initialValues = defaultInitialValues, loading }: Props) => {
  const { t } = useTranslation('login');

  return (
    <AuthStyledForm
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      size="large"
    >
      <Form.Item name="email" rules={[{ required: true, message: t('loginForm.email.required') }]}>
        <Input placeholder={t('loginForm.email.placeholder')} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t('loginForm.password.required') }]}>
        <Input type="password" placeholder={t('loginForm.password.placeholder')} />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox>{t('loginForm.remember')}</Checkbox>
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={loading} block className={style.action}>
        {t('loginForm.loginButton')}
      </Button>
    </AuthStyledForm>
  );
};
