export enum PageRoute {
  login = '/',
  registration = '/registration',
  registrationConfirmation = '/registration/confirmation',
  forgotPassword = '/forgot_password',
  confirmEmail = '/email/confirm',
  changeEmailConfirm = '/profile/changeEmail/confirm',
  newPassword = '/password/recovery',
  terms = '/terms',
  privacy = '/privacy',
  cookie = '/cookie',
}
