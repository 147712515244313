import { useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';

import { clearErrors, setErrors } from 'utils/form';
import { FieldErrors } from 'modules/api-requests/entities';

const isEmpty = (errorFields?: FieldErrors) => {
  if (!errorFields) return true;
  return Object.keys(errorFields).length === 0;
};

type Props = {
  form: FormInstance;
  clearError: () => void;
  errorFields: FieldErrors;
  errors?: string[];
  errorText?: string;
  onError?: () => void;
};

export default ({ form, clearError, errorFields, errors, errorText, onError = () => void 0 }: Props) => {
  useEffect(() => {
    if (!isEmpty(errorFields)) {
      setErrors(form, errorFields);
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields, errors, errorText]);

  useEffect(() => {
    clearErrors(form, errorFields, clearError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearError]);
};
