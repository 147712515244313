export const getBadgeText = () => {
  const defaultBadge = '';

  if (process.env.REACT_APP_BADGE) {
    return process.env.REACT_APP_BADGE;
  }

  switch (process.env.NODE_ENV) {
    case 'development': {
      return 'dev';
    }

    case 'test': {
      return 'test';
    }

    default: {
      return defaultBadge;
    }
  }
};
