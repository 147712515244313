const localStorageRTKey = 'authApp:RT';
const localStorageACKey = 'autApp:AC';

function clearRefreshToken() {
  localStorage.removeItem(localStorageRTKey);
}

function getRefreshToken() {
  return localStorage.getItem(localStorageRTKey);
}

function saveAuthCode(authCode: string) {
  localStorage.setItem(localStorageACKey, authCode);
}

function clearAuthCode() {
  localStorage.removeItem(localStorageACKey);
}

function getAuthCode() {
  return localStorage.getItem(localStorageACKey);
}

export const storage = {
  getRefreshToken,
  clearRefreshToken,
  saveAuthCode,
  getAuthCode,
  clearAuthCode,
};
