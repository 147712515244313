import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { AuthStyledForm } from 'components/common/forms/AuthStyleForm';

export type ForgotPasswordFormValues = {
  email: string;
};

type Props = {
  onSubmit: (values: ForgotPasswordFormValues) => void;
  onValuesChange: (values: ForgotPasswordFormValues) => void;
  isLoading: boolean;
};

export const ForgotPasswordForm = ({ onSubmit, onValuesChange, isLoading }: Props) => {
  const { t } = useTranslation('forgotPassword');

  return (
    <AuthStyledForm
      name="login_form"
      layout="vertical"
      onFinish={onSubmit}
      size="large"
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          {
            type: 'email',
            message: t('emailField.pattern'),
          },
          {
            required: true,
            message: t('emailField.required'),
          },
        ]}
      >
        <Input placeholder={t('emailField.placeholder')} />
      </Form.Item>

      <Button type="primary" htmlType="submit" loading={isLoading} className="w-100" style={{ fontWeight: 600 }}>
        {t('resetButton')}
      </Button>
    </AuthStyledForm>
  );
};
