/**
 * Code from this page https://learn.javascript.ru/cookie
 */

type CookieProps = {
  expires?: string | Date;
  'max-age'?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  samesite?: string;
};

export const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string | number, props: CookieProps = {}) => {
  const options = {
    path: '/',
    ...props,
  };

  const exp = options.expires;
  if (exp && exp instanceof Date) {
    options.expires = exp.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  (Object.keys(options) as Array<keyof CookieProps>).forEach((optionKey) => {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  });

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', { 'max-age': -1 });
};
