import { useTranslation } from 'react-i18next';
import { Result, Button, Layout } from 'antd';
import { Helmet } from 'react-helmet-async';

import { FormContainer } from 'components/common/forms/form-container';
import { Footer } from 'components/layout/footer';
import { PageRoute } from 'components/routing/utils';
import { MondiadLogo } from 'components/common/MondiadLogo';

export const EmailConfirmation = () => {
  const { t } = useTranslation('confirmEmail');

  return (
    <Layout>
      <Layout.Content>
        <FormContainer withMarginTop>
          <MondiadLogo />
          <Helmet title="Mondiad" />
          <Result
            status="success"
            title={t('successMessage')}
            extra={[
              <Button href={PageRoute.login} key="login">
                {t('loginButton')}
              </Button>,
            ]}
          />
        </FormContainer>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};
