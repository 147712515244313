import { ReactNode } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import styles from './style.module.scss';

type Props = LinkProps & {
  underlined?: boolean;
  children: ReactNode;
};

export const Link = ({ children, underlined, ...linkProps }: Props) => {
  const classes = classNames([linkProps.className, styles.root, { [styles.underlined]: Boolean(underlined) }]);
  return (
    <RouterLink {...linkProps} className={classes}>
      {children}
    </RouterLink>
  );
};
