import { Anchor } from 'antd';
import * as React from 'react';
import { CSSProperties } from 'react';

export interface AgreementMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: CSSProperties;
}

export const AgreementMenu = (props: AgreementMenuProps) => {
  return (
    <Anchor style={props.style}>
      <Anchor.Link href="#terms-and-conditions" title="Terms and Conditions">
        <Anchor.Link href="#1-acceptance-of-terms" title="1. Acceptance of terms" />
        <Anchor.Link href="#2-definitions" title="2. Definitions" />
        <Anchor.Link
          href="#3-description-of-first-media-ltd-services"
          title="3. Description of First Media Ltd services"
        />
      </Anchor.Link>
      <Anchor.Link href="#4-user-registration" title="4. User registration">
        <Anchor.Link href="#4-1-registration-obligations" title="4.1 Registration obligations" />
        <Anchor.Link
          href="#4-2-member-account-password-and-security"
          title="4.2 Member account, password and security"
        />
        <Anchor.Link
          href="#4-3-your-ability-to-edit-and-delete-your-account-information"
          title="4.3 Your ability to edit and delete your account information"
        />
        <Anchor.Link href="#4-4-account-termination" title="4.4 Account termination" />
        <Anchor.Link href="#4-5-account-inactivity-fee" title="4.5 Account inactivity fee" />
      </Anchor.Link>
      <Anchor.Link href="#5-advertiser-agreement" title="5. Advertiser Agreement">
        <Anchor.Link href="#5-1-the-service" title="5.1 The Service" />
        <Anchor.Link href="#5-2-reporting" title="5.2 Reporting" />
        <Anchor.Link href="#5-3-advertiser-deposits" title="5.3 Advertiser Deposits" />
        <Anchor.Link href="#5-4-fees-discrepancy-and-spending" title="5.4 Fees, Discrepancy and Spending" />
        <Anchor.Link href="#5-5-refund-policy" title="5.5 Refund Policy" />
      </Anchor.Link>
      <Anchor.Link href="#6-publisher-agreement" title="6. Publisher Agreement">
        <Anchor.Link href="#6-1-the-service" title="6.1 The Service" />
        <Anchor.Link href="#6-2-placement-of-ads" title="6.2 Placement of Ads" />
        <Anchor.Link href="#6-3-online-reports" title="6.3 Online reports" />
        <Anchor.Link href="#6-4-self-billing" title="6.4 Self-Billing" />
        <Anchor.Link href="#6-5-publisher-earnings" title="6.5 Publisher Earnings" />
        <Anchor.Link href="#6-6-publisher-payments" title="6.6 Publisher Payments" />
      </Anchor.Link>
      <Anchor.Link href="#7-fraudulent-activity" title="7. Fraudulent activity">
        <Anchor.Link href="#7-1-member-conduct" title="7.1 Member conduct" />
        <Anchor.Link href="#7-2-advertiser-fraud" title="7.2 Advertiser fraud" />
        <Anchor.Link href="#7-3-publisher-fraud" title="7.3 Publisher fraud" />
      </Anchor.Link>
      <Anchor.Link href="#8-privacy-policy" title="8. Privacy policy">
        <Anchor.Link href="#8-1-general-information" title="8.1 General information" />
        <Anchor.Link href="#8-2-information-collection-and-use" title="8.2 Information collection and use" />
        <Anchor.Link href="#8-3-sharing-and-disclosure" title="8.3 Sharing and disclosure" />
        <Anchor.Link href="#8-4-confidentiality" title="8.4 Confidentiality" />
        <Anchor.Link href="#8-5-no-3rd-party-beneficiaries" title="8.5 No 3rd party beneficiaries" />
        <Anchor.Link href="#8-6-cookies-and-similar-technologies" title="8.6 Cookies and similar technologies" />
        <Anchor.Link href="#8-7-changes-to-this-policy" title="8.7 Changes to this policy" />
      </Anchor.Link>
      <Anchor.Link href="#9-intellectual-property-rights" title="9. Intellectual Property Rights" />
      <Anchor.Link href="#10-waiver" title="10. Waiver">
        <Anchor.Link
          href="#10-1-representations-warranties-and-covenants"
          title="10.1 Representations, Warranties and Covenants"
        />
        <Anchor.Link
          href="#10-2-limitation-of-liability-disclaimer-of-warranty"
          title="10.2 Limitation of Liability; Disclaimer of Warranty"
        />
        <Anchor.Link href="#10-3-indemnity" title="10.3 Indemnity" />
        <Anchor.Link href="#10-4-severability" title="10.4 Severability" />
      </Anchor.Link>
      <Anchor.Link href="#11-force-majeure" title="11. Force Majeure" />
      <Anchor.Link href="#12-miscellaneous" title="12. Miscellaneous" />
      <Anchor.Link href="#13-data-processing-agreement" title="13. Data processing agreement">
        <Anchor.Link href="#13-1-definitions" title="13.1 Definitions" />
        <Anchor.Link href="#13-2-processing-of-personal-data" title="13.2 Processing Of Personal Data" />
        <Anchor.Link href="#13-3-rights-of-data-subjects" title="13.3 Rights Of Data Subjects" />
        <Anchor.Link href="#13-4-first-media-ltds-staff" title="13.4 First Media Ltd’s staff" />
        <Anchor.Link href="#13-5-security" title="13.5 Security" />
        <Anchor.Link
          href="#13-6-security-breach-management-and-notification"
          title="13.6 Security Breach Management And Notification"
        />
        <Anchor.Link
          href="#13-7-subprocessing-and-transborder-data-transfers"
          title="13.7 Subprocessing And Transborder Data Transfers"
        />
        <Anchor.Link href="#13-8-others" title="13.8 Others" />
        <Anchor.Link
          href="#13-9-list-of-first-media-ltds-sub-processors"
          title="13.9 List of First Media Ltd’s Sub-Processors"
        />
      </Anchor.Link>
    </Anchor>
  );
};
