import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

export type FormContainerProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode;
  oneColumn?: boolean;
  withMarginTop?: boolean;
};

export const FormContainer = ({ children, oneColumn, withMarginTop, ...props }: FormContainerProps) => {
  return (
    <div
      className={classnames(style.block, {
        [style.block_oneColumn]: oneColumn,
        [style.block_withMarginTop]: withMarginTop,
      })}
    >
      <div
        className={classnames('card', style.inner, {
          [style.inner_oneColumn]: oneColumn,
        })}
        style={props.style}
      >
        {children}
      </div>
    </div>
  );
};
