import { httpClient } from 'httpClient';
import { apiVersion, authHost } from 'modules/api-requests/const';
import { CountryDictionary, MessengerDictionary } from './entities';
import { ResponseAPI } from '../entities';

export const getMessengersDictionary = (): Promise<ResponseAPI<MessengerDictionary>> => {
  return httpClient.get(`${authHost}/api/${apiVersion}/dictionary/messenger/list`);
};

export const getCountriesDictionary = () => {
  return httpClient.get(`${authHost}/api/${apiVersion}/dictionary/country/list`) as Promise<
    ResponseAPI<CountryDictionary>
  >;
};
