import { Route, Routes, createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { PageRoute } from './utils';

import { LoginPage } from 'components/login';
import { RegistrationPage } from 'components/registration';
import { RegistrationConfirmPage } from 'components/registration/confirm';
import { ForgotPasswordPage } from 'components/forgotPassword';
import { ConfirmEmailPage } from 'components/confirmEmail/ConfirmEmailPage';
import { ChangeConfirmEmailPage } from 'components/confirmEmail/ChangeConfirmEmailPage';
import { NewPasswordPage } from 'components/newPassword';
import { TermsPage } from 'components/terms';
import { CookiePage } from 'components/cookie';
import { PrivacyPage } from 'components/privacy';

import { RootBoundary } from './RootBoundary';

export const Root = () => {
  return (
    <Routes>
      <Route path={PageRoute.login} Component={LoginPage} />
      <Route path={PageRoute.registration} Component={RegistrationPage} />
      <Route path={PageRoute.registrationConfirmation} Component={RegistrationConfirmPage} />
      <Route path={PageRoute.forgotPassword} Component={ForgotPasswordPage} />
      <Route path={PageRoute.confirmEmail} Component={ConfirmEmailPage} />
      <Route path={PageRoute.changeEmailConfirm} Component={ChangeConfirmEmailPage} />
      <Route path={PageRoute.newPassword} Component={NewPasswordPage} />
      <Route path={PageRoute.terms} Component={TermsPage} />
      <Route path={PageRoute.cookie} Component={CookiePage} />
      <Route path={PageRoute.privacy} Component={PrivacyPage} />
      <Route path="*" element={<Navigate to={PageRoute.login} replace />} />
    </Routes>
  );
};

const router = createBrowserRouter([{ path: '*', Component: Root, errorElement: <RootBoundary /> }]);

export const Routing = () => {
  return <RouterProvider router={router} />;
};
