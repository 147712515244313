import { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';

import style from './style.module.scss';

type Props = {
  children?: ReactNode;
} & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Anchor = ({ children, ...props }: Props) => {
  return (
    <a className={style.root} {...props}>
      {children}
    </a>
  );
};
