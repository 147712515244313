import { useEffect, useState } from 'react';
import queryString from 'query-string';

import { redirect } from 'utils/redirect';
import { getRedirectUrlRequest } from 'modules/api-requests/authorization';

import { storage } from './storage';

function saveAuthCode(authCode: string) {
  storage.saveAuthCode(authCode);
}

export function useUserRedirect() {
  const [redirecting, setRedirecting] = useState(false);

  async function tryRedirectUser() {
    const query = queryString.parse(window.location.search);
    const { logoutFrom } = query;

    if (logoutFrom) {
      storage.clearRefreshToken();
      storage.clearAuthCode();
      return;
    }

    // we no longer use the refresh token here, it will be removed in the future
    const refreshToken = storage.getRefreshToken();
    const authCode = storage.getAuthCode();
    const token = authCode || refreshToken;

    if (!token) return;

    setRedirecting(true);
    try {
      const {
        data: {
          data: { redirectUrl },
        },
      } = await getRedirectUrlRequest({ token });

      redirectUrl && redirect(redirectUrl);
    } catch {
      storage.clearRefreshToken();
      storage.clearAuthCode();
    } finally {
      setRedirecting(false);
    }
  }

  useEffect(() => {
    tryRedirectUser();
  }, []);

  return { saveAuthCode, redirecting };
}
