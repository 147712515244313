// we are going to refactor this part, so we can use `any` here
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from 'react';
import { RuleRender, FormInstance } from 'rc-field-form/lib/interface';
import { FieldErrors } from 'modules/api-requests/entities';

export const confirmedFieldValidator =
  (field: string): RuleRender =>
  ({ isFieldTouched, validateFields, getFieldValue }: FormInstance) => ({
    validateTrigger: 'onBlur',
    validator(rule: unknown, value: string) {
      if (value && isFieldTouched(field) && value !== getFieldValue(field)) {
        validateFields([field]);
      }

      return Promise.resolve();
    },
  });

export const confirmValidator =
  (field: string, errorText?: string): RuleRender =>
  ({ getFieldValue }: FormInstance) => ({
    validateTrigger: 'onBlur',
    validator(rule: unknown, value: string) {
      if (value !== getFieldValue(field)) {
        return Promise.reject(errorText || `The confirmation ${field} does not match`);
      }

      return Promise.resolve();
    },
  });

export const confirmedOnChangeValidator =
  (field: string): RuleRender =>
  ({ isFieldTouched, getFieldValue, setFields }: FormInstance) => ({
    validateTrigger: 'onChange',
    validator(rule: unknown, value: string) {
      const confirmedValue = getFieldValue(field);
      if (value && isFieldTouched(field) && value === confirmedValue) {
        setFields([
          {
            name: field,
            value: confirmedValue,
            errors: undefined,
          },
        ]);
      }

      return Promise.resolve();
    },
  });

export const serverErrorValidator =
  (field: string, errorValuesRef: MutableRefObject<any>, errorFields: FieldErrors | undefined): RuleRender =>
  () => ({
    validateTrigger: 'onBlur',
    validator(rule: unknown, value: string) {
      const errorValue = errorValuesRef.current && errorValuesRef.current[field];
      const serverError = errorFields && errorFields[field];
      if (errorValue && serverError && value === errorValue) {
        return Promise.reject(serverError);
      }

      return Promise.resolve();
    },
  });
