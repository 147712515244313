import { getCurrentYearRequest } from 'modules/api-requests/year';

let currentYear: number;
export const getCurrentYear = (): Promise<number> => {
  return currentYear
    ? Promise.resolve(currentYear)
    : getCurrentYearRequest().then(({ data: { data } }) => {
        currentYear = data;
        return data;
      });
};
