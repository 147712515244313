import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/loader';
import { recoveryConfirmRequest, recoveryTokenValidateRequest } from 'modules/api-requests/recoveryPassword';
import { LanguageKeys } from 'modules/localization/entities';
import { PageRoute } from 'components/routing/utils';

import { NewPasswordFormValues, NewPasswordForm } from './NewPasswordForm';
import { InvalidTokenScreen } from './InvalidTokenScreen';

export const NewPasswordPage = () => {
  const { t, i18n } = useTranslation('newPassword');
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const token = (query.token as string) || '';
  const language = i18n.language as LanguageKeys;
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const handleSubmit = useCallback(
    (values: NewPasswordFormValues) => {
      setIsPasswordUpdating(true);
      recoveryConfirmRequest({ password: values.password, token, language })
        .then(() => {
          setIsPasswordUpdating(false);
          notification.success({
            message: t('notifications.success'),
            placement: 'topRight',
          });
          navigate(PageRoute.login);
        })
        .catch(() => {
          setIsPasswordUpdating(false);
          notification.warn({
            message: t('notifications.error'),
          });
        });
    },
    [language, navigate, t, token]
  );

  useEffect(() => {
    setIsLoading(true);
    recoveryTokenValidateRequest({ token, language })
      .catch(() => setIsTokenInvalid(true))
      .finally(() => setIsLoading(false));
  }, [language, token]);

  if (isLoading) {
    return <Loader />;
  }

  if (isTokenInvalid) {
    return <InvalidTokenScreen />;
  }

  return <NewPasswordForm isLoading={isPasswordUpdating} handleSubmit={handleSubmit} />;
};
