import { httpClient } from 'httpClient';
import { apiVersion, authHost } from '../const';
import { RecoveryConfirmRequest, RecoveryRequest, RecoveryTokenValidateRequest } from './entities';

export const recoveryRequest = (data: RecoveryRequest) =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/recoveryPassword`, data);

export const recoveryConfirmRequest = (data: RecoveryConfirmRequest): Promise<void> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/recoveryPassword/confirm`, data);

export const recoveryTokenValidateRequest = (data: RecoveryTokenValidateRequest): Promise<void> =>
  httpClient.post(`${authHost}/api/${apiVersion}/auth/recovery/token/validate`, data);
