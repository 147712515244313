import { useEffect, useRef } from 'react';

export const usePrevious = <T>(prevValue: T, initialValue: T): T => {
  const refValue = useRef<T>(initialValue);

  useEffect(() => {
    refValue.current = prevValue;
  });

  return refValue.current;
};
