import { ReactNode } from 'react';
import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};
export const BoldTitle = ({ children, className }: Props) => {
  return <h2 className={classNames(style.boldTitle, className)}>{children}</h2>;
};
