import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';

import { PageRoute } from 'components/routing/utils';
import { Link } from 'components/common/link';
import { Anchor } from 'components/common/link/Anchor';
import { getCurrentYear } from 'utils/getCurrentYear';

import style from './style.module.scss';

const anchors = [
  ['support', `${process.env.REACT_APP_LANDING_HOST}/faq`],
  ['contact', `${process.env.REACT_APP_LANDING_HOST}/contact`],
];

const links = [
  ['termsOfUse', PageRoute.terms],
  ['cookiePolicy', PageRoute.cookie],
  ['privacyPolicy', PageRoute.privacy],
];

export const Footer = () => {
  const { t } = useTranslation('footer');
  const [currentYear, setCurrentYear] = useState<number>();

  useEffect(() => {
    getCurrentYear().then((year) => setCurrentYear(year));
  }, []);

  return (
    <Layout.Footer className={style.footer}>
      <div className={style.box}>
        <ul className={style.list}>
          {anchors.map(([i18n, href]) => (
            <li className={style.listItem} key={href}>
              <Anchor target="_blank" href={href}>
                {t(i18n)}
              </Anchor>
            </li>
          ))}
          {links.map(([i18n, path]) => (
            <li className={style.listItem} key={path}>
              <Link target="_blank" to={path}>
                {t(i18n)}
              </Link>
            </li>
          ))}
        </ul>
        <div className={style.copyright}>
          Copyright © 2020-<span>{currentYear}</span> Mondiad
        </div>
      </div>
    </Layout.Footer>
  );
};
